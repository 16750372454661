/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  FormLabel, Stack, Box, Button, ListItem, ListItemText, ListItemAvatar, Tooltip, CircularProgress, Alert, Dialog,
} from '@mui/material';
import DOMPurify from 'dompurify';
import Typography from '@mui/material/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import dayjs from 'dayjs';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import {
  Dropdown, FormInput, RadioButtons, Loader,
}
  from '../../../components';
import isEmpty, {
  formatDate, getAuthHeader, getCurrentQuarter, isNil,
  isNilOrEmptyString,
} from '../../../utils/utils';
import { CommHandler } from '../../../utils';
import { config } from '../../../config';
import { AddProductlevelFormWrapper } from './addProductlevelForm.styled';
import { ProjectData } from './type';
import { Editor } from '../../../components/CKEditor';
import ImagePreview from './ImagePreview';
import { addArtifactsConstants, constant } from '../../../libs/Constant';
import { useSelector } from 'react-redux';

const vectorImage = <img src="/Vector.png" alt="vector_img" className="va_m" />;
const AddProductLevelForm = (props: any) => {
  const { categoryId ,categoryNames} = useParams();
  const UserId = localStorage.getItem('user_id');
  const location = useLocation();
  const isEditPage = location.pathname.includes('editArtifact');
  const {
    data = {}, index, onChange = () => { }, artifactType, setSubmitEnabled, setHasLineItem, validationErrors, setSelectedQuarter, selectedQuarter,
    selectedCatId: selectedCatID, setValidationErrors, lineItem, setlineItem, fromDate, endDate, setFromDate, setEndDate, setQuarterData, quarterData, artifactCatId,
    setCategoryCode,categoryCode,setAddRating
  } = props;
  const selectedCatId = isEditPage ? categoryId : selectedCatID;
  const filesData = isEditPage ? categoryId : selectedCatID;
  const [files, setFiles] = useState<any[]>([]);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [isCurrentlyWorking, setisCurrentlyWorking] = useState<boolean>(false);
  const [subCategories, setSubCategories] = useState<[]>([]);
  const [tempPath, setTempPath] = useState<any[]>([]);
  const [subScore, setsubScore] = useState();
  const [lineItemScore, setlineItemScore] = useState('');
  const [isShow, setisShow] = React.useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [lineItemLabel, setLineItemLabel] = useState('');
  const [, setOpenfromDate] = useState(false);
  const [, setOpenToDate] = useState(false);
  const [url, setUrl] = useState('');
  const [fileUrl, setfileUrl] = useState('');
  const [fileType, setfileType] = useState('');
  const [showBuArtifact, setshowBuArtifact] = useState(false);
  const [projectOptions, setProjectOptions] = useState([]);
  const [instruction, setInstructions] = useState('');
  const [fileDetails, setFileDetails] = useState([]);
  const [userDesignation, setUserDesignation] = useState<string | null>(null);
  const [subcategorySelected, setSubcategorySelected] = useState(false);
  const [currentQuarter, setCurrentQuarter] = useState<string>('');
  const [selectedProject, setSelectedProject] = useState(null);
  const today = dayjs();
  const financialYearStart = today.month() >= 3 ? dayjs().startOf('year').month(3).date(1).startOf('day') : dayjs().subtract(1, 'year').startOf('year').month(3).date(1).startOf('day');
  const financialYearEnd = financialYearStart.add(1, 'year').subtract(1, 'day').endOf('day');
  const [ProjectMangerName,setProjectMangerName]=useState('')
  const [validationMessage, setValidationMessage] = useState<ProjectData>({
    artifact_category_id: '',
    artifact_sub_category_id: '',
    artifact_item_id: '',
    name: '',
    file_path: [''],
    preFilePath: [''],
    user_id: '',
    approved_by: '',
    year: '',
    from_date: '',
    to_date: '',
    project_manager_id: '',
    project_id: '',
    project_manager: [],
    description: '',
    areaOfImprovement: '',
    is_draft: false,
    project_working_status: false,
    achieved_points: 0,
    project_name: '',
    fileError: '',
    files: [],
    type: '',
    url: '',
    financial_year: '',
    preFiles: [],
  });
  const loggedUser = useSelector((state: any) => state?.user?.employee);
  const urlRegex = /^(https:\/\/)([\da-z.-]+)\.([a-z.]{2,})([/\w.-]*)*(#.*)?$/i;
  const department = getAuthHeader['x-department'] || loggedUser?.department;
  const rating_info=addArtifactsConstants.rating_info.split(",")
  useEffect(() => {
    async function fetchData() {
      try {
        const userProfileResponse: any = await CommHandler.request({
          url: `${config.apiBaseUrl}/users/${UserId}`,
          method: 'GET',
          headers: getAuthHeader,
        });
        const designation = userProfileResponse?.data?.profile?.designation;
        setUserDesignation(designation);

        const response = await CommHandler.request({
          url: `${config.adminApiURL}/artifact/category/${selectedCatId}`,
          method: 'GET',
          params: { status: 1 },
          headers: getAuthHeader,
        });
        const reqData = await response?.data;
        setCategoryCode(reqData.code)
        return reqData;
      } catch (err) {
        return false;
      }
    }

    fetchData().then((getData) => {
      if (getData) {
        const subData = getData?.subCategory?.filter((item) => {
          const hasBusinessUnit = item?.business_unit?.some((bu) => bu?.name === department || bu?.department === department);
          const hasDesignation = item?.designation?.some((des) => des?.name === userDesignation);

          return (
            item?.artifact_category_id.toString() === selectedCatId.toString()
            && item?.status === 1
            && hasBusinessUnit
            && hasDesignation
          );
        });
        setSubCategories(subData);
      }
    });
  }, [selectedCatId, department, UserId, userDesignation]);

  useEffect(() => {
    if (isEditPage && isFirst && data?.files?.length > 0 && files.length === 0) {
      const temp = data?.file_path?.map((filename: string) => ({ filename }));
      setFiles((prev) => [...prev, ...(data?.files ?? [])]);
      setTempPath((prev) => [...prev, ...temp]);
      setIsFirst(false);
    }
  }, [isEditPage, data?.files, data?.file_path, isFirst, files]);

  useEffect(() => {
    if (isEditPage && data.selectedlineItem) {
      const selectedSubcategory: any = subCategories.find((sub: any) => sub.id === data.artifact_sub_category_id);
      
      if (selectedSubcategory?.line_item) {
        const lineItems = selectedSubcategory.line_item.map((item: any, index: number) => ({
          id: index + 1,
          label: item.name,
          optionValue: item.name,
          score: item.score,
        }));
  
        setlineItem(lineItems);
        setHasLineItem(lineItems.length > 0);
        setisShow(true);
  
        const selectedItem = lineItems.find((item: any) => item.label === data.selectedlineItem);
        if (selectedItem) {
          setLineItemLabel(selectedItem.label);
          setlineItemScore(selectedItem.score || 1);
        }
      }
    }
  }, [isEditPage, data.selectedlineItem, data.artifact_sub_category_id, subCategories]);
  
  async function fetchProject() {
    try {
      const responseProject = await CommHandler.request({
        url: `${config.apiBaseUrl}/project`,
        method: 'GET',
        headers: getAuthHeader,
      });
      const reqData = await responseProject?.data;
      setProjectOptions(reqData);
    } catch (err) {
      return false;
    }
  }

  useEffect(() => {
    fetchProject();
  }, []);

  const onProjectNameChange = (projectName: string, projectManager: string, event: any, value: any) => {
    if (value === null || value === undefined) {
      const updatedData = {
        ...data,
        project_name: '',
        project_id: '',
        [projectManager]: [],
      };
      onChange(updatedData);
      return;
    }

    const selectedProject = projectOptions?.find((project) => project?.projectId === value?.optionValue);

    if (!selectedProject) {
      const updatedData = {
        ...data,
        project_name: '',
        project_id: '',
        [projectManager]: [],
      };
      onChange(updatedData);
      return;
    }

    const projectManagerDetails = Array.isArray(selectedProject.projectManagerList)
      ? selectedProject.projectManagerList.map(({ name, email }: { name: string, email: string }) => ({
        name,
        email,
      }))
      : [];

    const accountManagerDetails = Array.isArray(selectedProject.accountManagerList)
      ? selectedProject.accountManagerList.map(({ name, email }: { name: string, email: string }) => ({
        name,
        email,
      }))
      : [];

    const combinedManagerDetails = [...projectManagerDetails, ...accountManagerDetails];

    const updatedData = {
      ...data,
      project_id: selectedProject?.id.toString(),
      project_name: value.label,
      [projectManager]: combinedManagerDetails,
      selectedlineItem: lineItemLabel,
    };

    const newErrors = Array.isArray(validationErrors) ? [...validationErrors] : [];
    newErrors[index] = {
      ...newErrors[index],
      project_name: '',
      project_manager_id: '',
    };
    setValidationErrors(newErrors);
    onChange(updatedData);
    setProjectMangerName(combinedManagerDetails?.map((item)=>item?.name).join(', '))
    return {};
    };

  const projectOption = projectOptions?.map((projectName: any, indexId) => ({
    id: indexId + 1,
    label: projectName?.projectName,
    optionValue: projectName?.projectId,
  }));

  const updateItemField: any = (fieldName: string, updatedValue: string) => {
    const newErrors = { ...validationErrors };
    newErrors[fieldName] = updatedValue;
     setValidationErrors(newErrors);
   };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleFileSelect = async (item: any, ind: any) => {
    try {
      const response = await CommHandler.get(`${config.apiBaseUrl}/uploadFiles/get-signed-url`, {
        params: { key: item?.fileName },
        headers: getAuthHeader,
      });
      const signedUrl = response;

      if (signedUrl) {
        const str = `${config.s3URL}${config.tempBucket}/${item.fileName}`;
        const result = str?.substring(str.length - 3);
        setfileUrl(str);
        setfileType(result);
        setshowBuArtifact(true);
      } else {
        console.error('Signed URL is undefined');
      }
    } catch (error) {
      console.error('Error handling file select:', error.message);
    }
  };

  const formContainerStyles = {
    marginTop: '20px',
  };

  const handleUpload = async (files) => {
    setisLoading(true);
    try {
      const response = await CommHandler.post(`${config.apiBaseUrl}/uploadFiles/signedUrl`, { files });
      const presignedUrls = response?.data;
  
      await Promise.all(presignedUrls.map(async ({ url, fileName }, index) => {
        const file = files[index]?.fileObject;
        // eslint-disable-next-line no-param-reassign
        files[index].fileName = fileName;
        await axios.put(url, file, {
          headers: {
            'Content-Type': file.type,
          },
        });
      }));
  
      const filePath = presignedUrls.map((item) => ({
        filename: item.fileName,
      }));
  
      setFileDetails((prev) => [...prev, ...files]);
      setTempPath((existing) => [...existing, ...filePath]);
  
      const filesData = isEditPage ? data?.preFiles : [];
      const newData = {
        ...data,
        files: [...filesData, ...fileDetails, ...files],
        file_path: [...tempPath, ...filePath].map(({ filename }) => filename),
      };
      onChange(newData);
    } catch (e) {
      console.error(`Error while uploading file: ${e.message}`);
    } finally {
      setisLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setValidationMessage((prevMessages: any) => ({
        ...prevMessages,
        fileError: '',
      }));
      setisLoading(true);
      updateItemField('files', '');
      const invalid = acceptedFiles.filter((file) => file.size > 5242880);
      const invalidFileTypes = acceptedFiles.filter((file) => !['image/png', 'image/jpeg', 'image.jpg', 'application/pdf'].includes(file.type));
      const checkForInvalidExtensions = acceptedFiles.filter((file) => {
        const fileNameParts = file.name.split('.');
        return fileNameParts.length > 2 && fileNameParts.every((part, ind) => ind !== fileNameParts.length - 1);
      });
      if (checkForInvalidExtensions.length > 0) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: 'File(s) with invalid extensions',
        }));
        setisLoading(false);
      } else if (invalid.length) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: 'File Size cannot be more than 5MB',
        }));
        setisLoading(false);
      } else if (files.length + acceptedFiles.length > 5) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: 'You cannot upload more than 5 Files',
        }));
        setisLoading(false);
      } else if (
        acceptedFiles.some((obj1: any) => files.some((obj2: any) => obj1.name === obj2.name))
      ) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: '1 or more files already exists',
        }));
        setisLoading(false);
      } else if (invalidFileTypes.length) {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: 'Invalid file(s). File type can only be image or pdf',
        }));
        setisLoading(false);
        return false;
      } else {
        setValidationMessage((prevMessages: any) => ({
          ...prevMessages,
          fileError: '',
        }));
        const formData = new FormData();
        acceptedFiles.forEach((file: any) => formData.append('files', file));
        const allFile = acceptedFiles.map((file: any) => ({
          name: file.name,
          type: file.type,
          size: file.size,
          fileObject: file,
        }));
        setFiles((existing: any) => [...existing, ...allFile]);
        handleUpload(allFile);
      }
    },
  });
  const handleValidation = (feild: string, value: string) => {
    if (value === null || value === undefined || value.trim() === '') {
      setValidationMessage((prevMessages) => ({
        ...prevMessages,
        [feild]: 'This field is required',
      }));
    } else if (feild === constant.DESCRIPTION) {
      if (value.length > constant.DESCRIPTION_LIMIT_FEEDBACK) {
        setValidationMessage((prevMessages) => ({
          ...prevMessages,
          [feild]: constant.MAX_CHAR_LIMIT_DES,
        }));
      }
    } else if (feild === 'project_name') {
      setValidationMessage((prevMessage) => (
        {
          ...prevMessage,
          [feild]: '',
        }
      ));
    } else {
      setValidationMessage((prevMessages) => ({
        ...prevMessages,
        [feild]: '',
      }));
      updateItemField(feild, '');
    }
  };

  const onURLBlur = (value: string) => {
    if (!urlRegex.test(value)) {
      return setValidationMessage((prevMessages) => ({
        ...prevMessages,
        url: 'Invalid URL format',
      }));
    }
    setValidationMessage((prevMessages) => ({
      ...prevMessages,
      url: '',
    }));
  };
  function stripHtmlTags(input) {
    return input.replace(/<\/?[a-z][\s\S]*?>/gi, ""); 
  }
  function decodeHtmlEntities(input) {
    return input.replace(/&nbsp;/gi, " "); // Convert non-breaking spaces to regular spaces
  }
  
  const handleChange = (key: string, event: any) => {
    const { value } = event.target;
    const sanitizedInput = DOMPurify.sanitize(value);
    const validNamePattern = /^[a-zA-Z0-9\s.,'_]*$/; 
    const forbiddenCharsPattern = /[=+\-@|\x09|\x0D]/;
    if (sanitizedInput !== undefined && sanitizedInput !== null && sanitizedInput !== '') {
      setSubmitEnabled(false);
      setValidationMessage((prevMessages) => ({
        ...prevMessages,
        [key]: '',
      }));
      updateItemField(key, '');
    } else {
      setSubmitEnabled(true);
    }
    if (key === 'name') {
      if (!validNamePattern.test(sanitizedInput)||forbiddenCharsPattern.test(sanitizedInput)) {
        setSubmitEnabled(true);
        setValidationMessage((prevMessages) => ({
          ...prevMessages,
          [key]: 'The name field contains invalid characters in the name field',
        }));
      } else {
        setSubmitEnabled(false);
      }
    }
if (key === constant.DESCRIPTION) {
  if (sanitizedInput.length > constant.DESCRIPTION_LIMIT) {
    setSubmitEnabled(true);
    setValidationMessage((prevMessages) => ({
      ...prevMessages,
      [key]: constant.MAX_CHAR_LIMIT,
    }));
  }else {
    setSubmitEnabled(false);
    setValidationMessage((prevMessages) => ({
      ...prevMessages,
      [key]: "",
    }));
  }
}

    if (key === 'url') {
      setUrl(sanitizedInput);
      if (!urlRegex.test(sanitizedInput)) {
        setValidationMessage((prevMessages) => ({
          ...prevMessages,
          url: 'Invalid URL format',
        }));
      } else {
        setValidationMessage((prevMessages) => ({
          ...prevMessages,
          url: '',
        }));
      }
    }
    if (key === 'project_working_status') {
      setisCurrentlyWorking(sanitizedInput !== 'true');
    }
    const updatedData = {
      ...data, [key]: value,
    };
    onChange(updatedData);
  };
  const handleSubCategory = (key: string, event: any) => {
    const { value } = event.target;
    subCategories?.map((itm: any) => {
      /* eslint no-underscore-dangle: 0 */
      if (value === itm?.id) {
        if (!isEmpty(itm.line_item)) {
          const options = itm?.line_item?.map((item: any, indexId: any) => ({
            id: indexId + 1,
            label: item.name,
            optionValue: item.name,
            score: item.score,
          }));
          setSubcategorySelected(true);
          setlineItem(options);
          setHasLineItem(options && options.length > 0);
          setlineItemScore('');
          const updatedData = {
            ...data, 
            [key]: value, 
            achieved_points: !isNil(itm?.line_item?.[0]?.score) ? itm?.line_item?.[0]?.score : 1,
            feedback:!isNilOrEmptyString(itm?.name)?itm?.name?.toUpperCase():'',
            selectedlineItem: !isNilOrEmptyString(itm?.line_item?.[0]?.name) ? itm?.line_item?.[0]?.name : ''
          };
          setAddRating(!isNil(itm?.line_item?.[0]?.score) ? itm?.line_item?.[0]?.score : 1);
          handleLine("achieved_points",!isNilOrEmptyString(itm?.line_item?.[0]?.name) ? itm?.line_item?.[0]?.name : '',options)
          onChange(updatedData);
        } else {
          setlineItem([]);
          setHasLineItem(false);
          setsubScore(itm.sub_category_score);
          setlineItemScore(itm?.sub_category_score);
          setLineItemLabel('');
          const updatedData = {
            ...data, 
            [key]: value, 
            achieved_points: itm?.sub_category_score, selectedlineItem: '',
          };
          onChange(updatedData);
        }
        if (itm.instructions) {
          setInstructions(itm.instructions);
        } else {
          setInstructions('');
        }
        setisShow(true);
        setSubcategorySelected(true);
        return false;
      }
      return {};
    });
  };

  const handleLineItem = (key: string, event: any) => {
    const { value } = event.target;
    const filtelineitem = !isEmpty(lineItem)?lineItem?.filter((element: { label: any; }) => element?.label === value):[];
    setlineItemScore(!isNil(filtelineitem?.[0]?.score) ? filtelineitem?.[0]?.score : 1);
    setLineItemLabel(value);
    setAddRating(isNil(filtelineitem?.[0]?.score) ? 1 : filtelineitem?.[0]?.score);
    if (artifactType === 'SQU') {
      const updatedData = {
        ...data,
        project_name: '', 
        [key]: !isNil(filtelineitem?.[0]?.score) ? filtelineitem?.[0]?.score : 1,
        selectedlineItem: !isNilOrEmptyString(filtelineitem?.[0]?.label) ? filtelineitem?.[0]?.label : ''
      };
      onChange(updatedData);
    } else {
      const updatedData = {
        ...data, 
        [key]: !isNil(filtelineitem?.[0]?.score) ? filtelineitem?.[0]?.score : 1, 
        selectedlineItem: !isNilOrEmptyString(filtelineitem?.[0]?.label) ? filtelineitem?.[0]?.label : '',
      };
      onChange(updatedData);
    }
  };
  const handleEditorChange = (event: any, editor: any) => {
    handleChange(constant.DESCRIPTION, {
      target: {
        value: editor.getData(),
      },
    });
    handleQuarterChange(selectedQuarter - 1, 'description', editor.getData());
  };
  const handleEditorChangess = (event: any, editor: any) => {
    handleChange(constant.AREA_OF_IMPROVEMENT, {
      target: {
        value: editor.getData(),
      },
    });
    handleQuarterChange(selectedQuarter - 1, 'areaOfImprovement', editor.getData());
  };

  const handleEditorBlur = useCallback((event: any, editor: any) => {
    handleValidation(constant.DESCRIPTION, editor.getData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuarterChange = (index: number, field: string, value: string) => {
    setQuarterData((prevQuarterData) => {
      const updatedQuarterData = [...prevQuarterData];
      updatedQuarterData[index] = {
        ...updatedQuarterData[index],
        description: field === 'description' ? value : updatedQuarterData[index]?.description,
        areaOfImprovement: field === 'areaOfImprovement' ? value : updatedQuarterData[index]?.areaOfImprovement,
      };
      return updatedQuarterData;
    });
  };

  const handleDateChange = (key: string, date: number | null) => {
    if (date !== null) {
      setValidationMessage((prevMessages) => ({
        ...prevMessages,
        [key]: '',
      }));
      onChange({ ...data, [key]: formatDate(date) });
      updateItemField(key, '');
    } else {
      setValidationMessage((prevMessages) => ({
        ...prevMessages,
        [key]: 'This field is required',
      }));
      onChange({ ...data, [key]: null });
    }
  };

  function handleSubmit(event: { preventDefault: () => void }) {
    event.preventDefault();
  }

  const fileInputReference = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    setshowBuArtifact(false);
    if (fileInputReference.current) {
      fileInputReference.current.click();
    }
  };
  const removeElement = (i: any) => {
    const filteredFiles = files?.filter((file: any) => file?.fileName !== i);
    const filteredFilesData = data.files.filter((file: any) => file.fileName !== i);
    const filteredPreFilesData = data?.preFiles?.filter((file: any) => file?.fileName !== i);
    const filteredFilesDetails = fileDetails?.filter((file: any) => file?.fileName !== i);
    const filteredPAth = tempPath.filter((item: any) => item.filename !== i);
    setIsFirst(false);
    setValidationMessage((prevMessages: any) => ({
      ...prevMessages,
      fileError: '',
    }));
    setFileDetails(filteredFilesDetails);
    setFiles(filteredFiles);
    setTempPath(filteredPAth);
    onChange({
      ...data,
      preFiles: filteredPreFilesData,
      files: filteredFilesData,
      file_path: filteredPAth.map((item: any) => item.filename),
    });
  };

  const options = subCategories?.map((subcategory: any, indexId) => ({
    id: indexId + 1,
    label: subcategory?.name,
    optionValue: subcategory.id,
  }));
  // eslint-disable-next-line react/no-unstable-nested-components
  const LineItems = () => (lineItem.length ? (
    <>
      <div className="flex-column-container tooltipStyle">
        <FormLabel>
          {addArtifactsConstants.line_item}
          {' '}
          <Tooltip title={addArtifactsConstants.select_line_item_info} arrow>
            {vectorImage}
          </Tooltip>
          {' '}
        </FormLabel>
        <Dropdown
          inputLabel="Select Line Item"
          options={lineItem || []}
          value={lineItemLabel}
          width="100%"
          onChange={(e) => handleLineItem('achieved_points', e)}
        />
        {!lineItemLabel && validationErrors[index]?.selectedlineItem && (
        <span style={{ color: 'red' }}>{validationErrors[index]?.selectedlineItem}</span>
        )}
      </div>
      {lineItemScore && (
        <div
          style={{
            marginLeft: '50px',
            display: 'flex',
            flexDirection: 'column',
            width: '12%',
          }}
        >
          <FormLabel>
            {addArtifactsConstants.points_info}
            {' '}
            <Tooltip title={addArtifactsConstants.points_info} arrow>
              {vectorImage}
            </Tooltip>
            {' '}
          </FormLabel>
          <ListItem
            sx={{
              width: '80px',
              height: '40px',
              backgroundColor: '#FDF5EB',
              borderRadius: '5px',
              padding: '8px 8px 8px 8px',
              fontStyle: 'Inter',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '19.36px',
              color: '#EDA13B',
              gap: '8px',
              marginLeft: '-4',
            }}
          >
            <ListItemAvatar sx={{ minWidth: '0px !important' }} />
            <img
              src="/Page-1.png"
              width="24"
              height="24"
              alt=""
            />
            <ListItemText className="listIem-text" primary={lineItemScore} />
          </ListItem>
        </div>
      )}
    </>
  ) : (
    <div className="flex-column-container tooltipStyle" style={{ marginLeft: '100px' }}>
      <FormLabel>
        {addArtifactsConstants.points_info}
        {' '}
        <Tooltip title={addArtifactsConstants.points_info} arrow>
          {vectorImage}
        </Tooltip>
        {' '}
      </FormLabel>
      <ListItem
        sx={{
          width: '80px',
          height: '40px',
          backgroundColor: '#FDF5EB',
          borderRadius: '5px',
          padding: '8px 8px 8px 8px',
          fontStyle: 'Inter',
          fontWeight: '600',
          fontSize: '16px',
          lineHeight: '19.36px',
          color: '#EDA13B',
          gap: '8px',
          marginLeft: '0',
        }}
      >
        <ListItemAvatar sx={{ minWidth: '0px !important' }} />
        <img
          src="/Page-1.png"
          width="24"
          height="24"
          alt=""
        />
        <ListItemText className="listIem-text" primary={subScore} />
      </ListItem>
    </div>
  ));

  const handleLine = (key: string, value: any,lineItem:any) => {
    const filtelineitem = !isEmpty(lineItem)?lineItem?.filter((element: { label: any; }) => element?.label === value):[];
    setlineItemScore(!isNil(filtelineitem?.[0]?.score) ? filtelineitem?.[0]?.score : 1);
    setLineItemLabel(value);
    if (artifactType === 'SQU') {
      const updatedData = {
        ...data, project_name: '', [key]: !isNil(filtelineitem?.[0]?.score) ? filtelineitem?.[0]?.score : 1, selectedlineItem: !isNilOrEmptyString(filtelineitem?.[0]?.label) ? filtelineitem?.[0]?.label : '' ,
      };
      onChange(updatedData);
    } else {
      const updatedData = {
        ...data, [key]: !isNil(filtelineitem?.[0]?.score) ? filtelineitem?.[0]?.score : 1, selectedlineItem: !isNilOrEmptyString(filtelineitem?.[0]?.label) ? filtelineitem?.[0]?.label : '',
      };
      onChange(updatedData);
    }
  };

  useEffect(() => {
    if (isEditPage && data?.project_manager) {
      setProjectMangerName(data?.project_manager?.map((item) => item?.name).join(', '));
    }
  }, [isEditPage, data?.project_manager]); 

  useEffect(() => {
    if (data.project_name && projectOption?.length > 0) {
      const matchedProject = projectOption?.find((option) => option.label === data.project_name);
      setSelectedProject(matchedProject || null);
    }
  }, [data?.project_name, projectOption]);

  return (
    <AddProductlevelFormWrapper>
      {isLoading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
        }}
        >
          <CircularProgress size={50} />
        </div>
      )}
      <div className="add-artifact-wrapper">
        <div
          className="artifact-header-section"
          role="button"
        >
          <div className="header-left-section">
            {isEditPage ? (
              <div className="form-title">
                {addArtifactsConstants.edit_title}
              </div>
            ) : (
              <div className="form-title">
                {addArtifactsConstants.add_title}
              </div>
            )}
          </div>
        </div>
        <div className="artifact-form-section">
          <Typography sx={formContainerStyles}>
            <form onSubmit={handleSubmit} style={{ width: '100%', padding: '20px' }} autoComplete="off">
              <Stack spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                <div className="flex-column-container tooltipStyle" style={{ width: '100%' }}>
                  <FormLabel>
                    {addArtifactsConstants.artifcat_name}
                    {' '}
                    <Tooltip title={addArtifactsConstants.artifact_name_info} arrow>
                      {vectorImage}
                    </Tooltip>
                  </FormLabel>
                  <FormInput
                    inputId="basic-input"
                    required
                    inputBoxType="outlined"
                    type="input"
                    disabled={false}
                    defaultValue={data.name}
                    InputLabelShrink={false}
                    maxLength={300}
                    onValueChange={(e) => handleChange('name', e)}
                    onBlur={() => handleValidation('name', data.name)}
                  />
                  {validationErrors[index]?.name ? <span style={{ color: 'red' }}>{validationErrors[index]?.name}</span> : <span style={{ color: 'red' }}>{validationMessage.name}</span>}
                  {data.name.length >= 300 && <span style={{ color: 'red' }}>You have reached maximum character limits of 300 characters</span>}
                </div>
                {(artifactType === 'PRO'||categoryNames==='Project') && (
                <div className="flex-column-container tooltipStyle" style={{ width: '100%' }}>
                  <FormLabel>
                    {addArtifactsConstants.project_name}
                    {' '}
                    <Tooltip title={addArtifactsConstants.project_name_info} arrow>
                      {vectorImage}
                    </Tooltip>
                    {' '}
                  </FormLabel>
                  <Autocomplete
                    disablePortal
                    id="project-name"
                    options={projectOption}
                    getOptionLabel={(option) => option.label}
                    value={selectedProject}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(e, value) => onProjectNameChange('project_id', 'project_manager', e, value)}
                    renderInput={(params) => <TextField {...params} label="Project name" />}
                    renderOption={(prop, item) => (
                      <li {...prop} key={item.id}>
                        {item.label}
                      </li>
                    )}
                  />
                  {validationErrors[index]?.project_name ? <span style={{ color: 'red' }}>{validationErrors[index]?.project_name}</span> : <span style={{ color: 'red' }}>{validationMessage.project_name}</span>}
                </div>
                )}
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                {(artifactType === 'PRO'||categoryNames==='Project') && (
                <div className="flex-column-container">
                  <FormLabel>
                    {addArtifactsConstants.project_manager}
                    {' '}
                    <Tooltip title={addArtifactsConstants.project_manager_info} arrow>
                      {vectorImage}
                    </Tooltip>
                    {' '}
                  </FormLabel>
                  <FormInput
                    inputBoxType="outlined"
                    type="input"
                    disabled
                    defaultValue={ProjectMangerName}
                    value={ProjectMangerName}
                    maxLength={30}
                  />
                  {validationErrors[index]?.project_manager_id ? <span style={{ color: 'red' }}>{validationErrors[index]?.project_manager_id}</span> : <span style={{ color: 'red' }}>{validationMessage.project_manager_id}</span>}
                </div>
                )}
              </Stack>
              <Stack spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                <div className="flex-column-container select-box-focused tooltipStyle">
                  <FormLabel>
                    {addArtifactsConstants.artifact_subcategory}
                    {' '}
                    <Tooltip title={addArtifactsConstants.select_subcategory_info} arrow>
                      {vectorImage}
                    </Tooltip>
                    {' '}
                  </FormLabel>
                  <Dropdown
                    inputLabel="Select Artifact Subcategory"
                    options={options || []}
                    value={data.artifact_sub_category_id}
                    onChange={(e) => handleSubCategory('artifact_sub_category_id', e)}
                    width="100%"
                    />
                  {validationErrors[index]?.artifact_sub_category_id && !subcategorySelected ? (
                    <span style={{ color: 'red' }}>
                      {validationErrors[index]?.artifact_sub_category_id}
                    </span>
                  ) : <span style={{ color: 'red' }}>{validationMessage?.artifact_sub_category_id}</span>}
                </div>
                {isShow && LineItems()}
              </Stack>             
              <Stack spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                {instruction && (
                <Alert sx={{ fontWeight: 700, marginTop: '24px', width: '100%' }} severity="info" className="alertBox">
                  <strong>Instructions</strong>
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(instruction) }} />
                </Alert>
                )}
              </Stack>

              <Stack spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                {artifactType === 'SQU' && (
                <div className="flex-column-container tooltipStyle">
                  <FormLabel>
                    {addArtifactsConstants.url}
                    {' '}
                    <Tooltip title={addArtifactsConstants.add_url_info} arrow>
                      {vectorImage}
                    </Tooltip>
                    {' '}
                  </FormLabel>
                  <FormInput
                    inputId="url-input"
                    inputBoxType="outlined"
                    type="url"
                    disabled={false}
                    maxLength={300}
                    defaultValue={url}
                    value={url}
                    InputLabelShrink
                    onValueChange={(e) => handleChange('url', e)}
                    onBlur={() => onURLBlur(data?.url)}
                  />
                  {validationErrors[index]?.url ? <span style={{ color: 'red' }}>{validationErrors[index]?.url}</span> : <span style={{ color: 'red' }}>{validationMessage?.url}</span>}
                </div>
                )}
              </Stack>
              {artifactType === 'SQU' && lineItemLabel === 'Project POC' && (
                <div className="flex-column-container tooltipStyle">
                  <FormLabel>
                    {addArtifactsConstants.project_name}
                    {' '}
                    <Tooltip title={addArtifactsConstants.project_name_info} arrow>
                      {vectorImage}
                    </Tooltip>
                    {' '}
                  </FormLabel>
                  <Autocomplete
                    disablePortal
                    id="project-name"
                    options={projectOption}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, value) => onProjectNameChange('project_id', 'project_manager', e, value)}
                    renderInput={(params) => <TextField {...params} label="Project name" />}
                    renderOption={(prop, item) => (
                      <li {...prop} key={item.id}>
                        {item.label}
                      </li>
                    )}
                  />
                  {validationErrors[index]?.project_name ? <span style={{ color: 'red' }}>{validationErrors[index]?.project_name}</span> : <span style={{ color: 'red' }}>{validationMessage.project_name}</span>}
                </div>
              )}

              {(artifactType === 'PRO'||categoryNames==='Project') && (
                <>
                  <Stack className="form-field-stack-wrapper" spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                    <div className="flex-column-container tooltipStyle">
                      <FormLabel>
                        {addArtifactsConstants.currently_project}
                        {' '}
                        <Tooltip title={addArtifactsConstants.project_status_info} arrow>
                          {vectorImage}
                        </Tooltip>
                        {' '}
                      </FormLabel>
                      <RadioButtons
                        isRow
                        status={data.project_working_status}
                        onValueChange={(e) => handleChange('project_working_status', e)}
                        radioButtonLabels={[
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                          {
                            label: 'No',
                            value: 'false',
                          },
                        ]}
                      />
                    </div>
                  </Stack>
                  <Stack className="form-field-stack-wrapper" spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                    <Stack className="duration_block" spacing={2} direction="row" sx={{ marginBottom: 2, width: '100%' }}>
                      <div className="form-duration-wrapper">
                        <div className="flex-column-container tooltipStyle">
                          <FormLabel>
                            {addArtifactsConstants.duration}
                            {' '}
                            <Tooltip title={addArtifactsConstants.project_duration_info} arrow>
                              {vectorImage}
                            </Tooltip>
                            {' '}
                          </FormLabel>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div onClick={() => setOpenfromDate((prev) => !prev)} onKeyPress={() => null} role="button" tabIndex={0} aria-label="Open Date Picker">
                              <DatePicker
                                sx={{ width: '100%' }}
                                label="From"
                                value={fromDate}
                                onChange={(value: any) => {
                                  if(value){
                                    const selectedDate = dayjs(value);
                                    setFromDate(selectedDate);
                                    if (selectedDate.isValid()) {
                                         handleDateChange('from_date', selectedDate.valueOf());
                                      }
                                    setOpenfromDate(false);
                                  }
                                }}
                                minDate={financialYearStart}
                                maxDate={financialYearEnd}
                              />
                            </div>
                          </LocalizationProvider>
                          {validationErrors[index]?.from_date ? <span style={{ color: 'red' }}>{validationErrors[index]?.from_date}</span> : <span style={{ color: 'red' }}>{validationMessage.from_date}</span>}
                        </div>
                        {isCurrentlyWorking && (
                          <div className="flex-column-container" style={{ marginTop: '28px', marginLeft: '15px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <div onClick={() => setOpenToDate((prev) => !prev)} onKeyPress={() => null} role="button" tabIndex={0} aria-label="Open Date Picker">

                                <DatePicker
                                  sx={{ width: '100%' }}
                                  label="To"
                                  value={endDate ? dayjs(endDate) : null}
                                  onChange={(value) => {
                                    if (value) {
                                      const selectedDate = dayjs(value);
                                      setEndDate(selectedDate);
                                      handleDateChange('to_date', selectedDate.valueOf());
                                      setOpenToDate(false);
                                    }
                                  }}
                                  minDate={fromDate ? dayjs(fromDate) : financialYearStart}
                                  maxDate={financialYearEnd}
                                  disabled={!fromDate}
                                />

                              </div>
                            </LocalizationProvider>
                            {validationErrors[index]?.to_date ? <span style={{ color: 'red' }}>{validationErrors[index]?.to_date}</span> : <span style={{ color: 'red' }}>{validationMessage.to_date}</span>}
                          </div>
                        )}
                      </div>
                    </Stack>
                  </Stack>
                </>
              )}
              <div className="flex-row-container" style={{ marginTop: '15px' }}>
                <div className="flex-column-container tooltipStyle" style={{ width: '100%' }}>
                  {categoryCode === 'FB' ? (
                    <>
                      <FormLabel>
                        Feedback
                        {' '}
                        <Tooltip title={addArtifactsConstants.artifact_table_info} arrow>
                          {vectorImage}
                        </Tooltip>
                        {' '}
                      </FormLabel>
                      <TextField
                        type="text"
                        value={`Quarter ${selectedQuarter}` || ''}
                        disabled
                        variant="outlined"
                        size="small"
                      />
                      {selectedQuarter && (
                      <>
                          <FormLabel sx={{ marginTop: '11px' }}>
                            {addArtifactsConstants.artifacts_description}{' '}
                            <Tooltip title={addArtifactsConstants.artifact_description_info} arrow>
                              {vectorImage}
                            </Tooltip>
                          </FormLabel>
                          <div className="ck-editor">
                          <Editor content={data.description} onChange={handleEditorChange} onBlur={handleEditorBlur} styleSet={undefined} />
                          </div>
                          {validationErrors[selectedQuarter - 1]?.description ? (
                            <span style={{ color: 'red' }}>{validationErrors[selectedQuarter - 1]?.description}</span>
                          ) : (
                            <span style={{ color: 'red' }}>{validationMessage.description}</span>
                          )}
                          <FormLabel sx={{ marginTop: '11px' }}>
                            {"Area of Improvement*"}
                            <Tooltip title={"Area of Improvement*"} arrow>
                              {vectorImage}
                            </Tooltip>
                          </FormLabel>
                          <div className="ck-editor">
                          <Editor content={data.areaOfImprovement?data.areaOfImprovement:data.area_of_improvement} onChange={handleEditorChangess} onBlur={handleEditorBlur} styleSet={undefined} />
                          </div>
                          {validationErrors[selectedQuarter - 1]?.areaOfImprovement ? (
                            <span style={{ color: 'red' }}>{validationErrors[selectedQuarter - 1]?.areaOfImprovement}</span>
                          ) : (
                            <span style={{ color: 'red' }}>{validationMessage.areaOfImprovement}</span>
                          )}
                      </>
                      )}
                    </>

                  ) : (
                    <>
                      <FormLabel>
                        {addArtifactsConstants.artifacts_description}
                        {' '}
                        <Tooltip title={addArtifactsConstants.artifact_description_info} arrow>
                          {vectorImage}
                        </Tooltip>
                        {' '}
                      </FormLabel>
                      <div className="ck-editor">
                        <Editor content={data.description} onChange={handleEditorChange} onBlur={handleEditorBlur} styleSet={undefined} />
                      </div>
                      {validationErrors[index]?.description ? <span style={{ color: 'red' }}>{validationErrors[index]?.description}</span> : <span style={{ color: 'red' }}>{validationMessage.description}</span>}
                    </>
                  )}

                </div>
              </div>
              <div className="flex-row-container" style={{ marginTop: '15px' }}>
                <div className="flex-column-container tooltipStyle" style={{ width: '100%' }}>
                  <FormLabel>
                    {categoryCode === 'FB'? (
                      addArtifactsConstants.upload_document_feedback
                    ) : (
                      addArtifactsConstants.upload_document
                    )}
                    {' '}
                    <Tooltip title={addArtifactsConstants.upload_images_info} arrow>
                      {vectorImage}
                    </Tooltip>
                    {' '}
                  </FormLabel>
                  {files.length > 0
                    && (
                      <Box
                        sx={{
                          width: '100%',
                          padding: '8px 16px',
                          backgroundColor: 'rgba(200, 200, 200, 0.05)',
                          border: '2px solid rgba(166, 167, 168, 0.30)',
                          borderRadius: '6px',
                          alignItems: 'left',
                          gap: '24px',
                          alignSelf: 'stretch',
                          flexDirection: 'row',
                          display: 'flex',
                          marginBottom: '2px',
                          flexWrap: 'wrap',
                        }}
                      >
                        {files?.map((file: any, ind: any) => {
                          const filtType = file.name.split('.');
                          if (isNil(file?.fileName)) {
                            return <Loader />;
                          }

                          return (
                            <div key={file?.fileName}>
                              <div className="preview-image-add">
                                {filtType[filtType.length - 1] === 'pdf'
                                  ? <PictureAsPdfIcon style={{ height: '60px', width: '60px', color: '#5788d7' }} />
                                  : (
                                    <img
                                      src={`${config.s3URL}${config.tempBucket}/${file?.fileName}`}
                                      alt={file?.name || 'Attachment'}
                                      style={{
                                        width: 'auto', height: '60px',
                                      }}
                                    />
                                  )}
                                <VisibilityOutlinedIcon className="view-icon" sx={{ color: '#ffffff', cursor: 'pointer' }} onClick={() => handleFileSelect(file, ind)} />
                                <CloseIcon
                                  className="close-icon"
                                  onClick={() => removeElement(file?.fileName)}
                                />

                              </div>
                            </div>

                          );
                        })}
                      </Box>
                    )}
                  <Box {...getRootProps()} className="drag-drop-wrapper">
                    <CloudUploadOutlinedIcon fontSize="large" className="upload-files-icon" />
                    <h2 className="upload-files-text">{addArtifactsConstants.drag_drop}</h2>
                    or
                    <input {...getInputProps()} accept="image/jpeg, image/png, application/pdf" />
                    {isLoading ? (
                      <Button variant="outlined" disabled={isLoading}>
                        <CircularProgress size={20} color="inherit" />
                      </Button>
                    ) : (
                      <Button variant="outlined" onClick={handleClick}>
                        {addArtifactsConstants.choose_files}
                      </Button>
                    )}
                  </Box>
                  {validationErrors[index]?.files ? <span style={{ color: 'red' }}>{validationErrors[index]?.files}</span> : <span style={{ color: 'red' }}>{validationMessage.fileError}</span>}
                </div>
              </div>
            </form>
          </Typography>
        </div>
      </div>
      {showBuArtifact && (
        <Dialog
          open={showBuArtifact}
          onClose={() => setshowBuArtifact(false)}
          PaperProps={{
            style: { width: '80%', maxWidth: 'none' },
          }}
        >
          <ImagePreview type={fileType} url={fileUrl} setshowBuArtifact={setshowBuArtifact} />
        </Dialog>
      )}
    </AddProductlevelFormWrapper>
  );
};

export default AddProductLevelForm;
