/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable */
import React, { useState, SyntheticEvent, useEffect } from 'react';
import { Box, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Tab, Tooltip } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Typography from '../../components/Typography/Typography';
import { ArtifactWrapper } from './BUArtifact.styled';
import { Dropdown, ListTable } from '../../components';
import { categories } from '../../libs/Constant';
import { CommHandler } from '../../utils';
import { config } from '../../config';
import { getAuthHeader, getCurrentYear } from '../../utils/utils';
import { setTabValue } from '../Profile/selectedStatusSlice';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';
import { convertArrayOfObjectsToCsv } from '../../utils/CsvHelper';
import { checkPermissions } from '../../utils/roles/permissionUtils';

export default function Artifact() {
  const [tabData, setTabData] = useState([]);
  const [data, setData] = useState({});
  const [loadingTable, setLoadingTable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedUser = useSelector((state: any) => state.user.employee);
  const currentYear = (
    useSelector((state: any) => state.appraisalCycle.selectedYear)
    || getCurrentYear()
  )?.toString();
  const hasDownloadPermission = checkPermissions('PMSTEAMPER:SUBDOWNREP', ['Read', 'Edit', 'Write', 'Delete'], true);

  const { selectedStatus: selectStatus, hasArtifact, tabValue } = useSelector((state: any) => state?.selectedStatus);
  const categoryList = [
    { id: 0, artifact: 'Has Artifact' },
    { id: 1, artifact: 'No Artifact' },
    { id: 2, artifact: 'All' },
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [selectedCategory, setSelectedCategory] = useState(null);
  useEffect(() => {
    setTabData(data[tabValue]);
  }, [data]);

  const handleTabChange = async (event: SyntheticEvent, newValue: any) => {
    dispatch(setTabValue(newValue));
    setTabData(data[newValue]);
  };

  const handleActionClick = (action: any, item: any) => {
    const userId = item?.user_id;
    if (action.label === 'View') {
      navigate(`/user-artifact-detail/${userId}`);
    }
  };

  const handleCheckboxChange = async (showArtifacts) => {
    try {
      setLoadingTable(true);

      const params = {
        hasArtifact: showArtifacts ? 1 : 0,
        approved: selectStatus?.join(',').toLocaleLowerCase(),
        year: currentYear,
      };

      if (!showArtifacts) {
        delete params.hasArtifact;
      }
      if (!selectStatus.length) {
        delete params.approved;
      }

      const response = await CommHandler.request({
        url: `${config.apiBaseUrl}/artifact/users`,
        method: 'GET',
        params,
        headers: getAuthHeader,
      });
      if (response?.data?.length) {
        setData(response?.data[0] || {});
      }
    } catch (e) {
      setLoadingTable(false);
    } finally {
      setLoadingTable(false);
    }
  };

  useEffect(() => {
    handleCheckboxChange(hasArtifact);
  }, [selectStatus, currentYear]);

  const getTabComponent = () => Object.keys(categories)
    .filter((key) => {
      switch (categories[key]) {
        case categories.OTHERS: {
          return data?.[categories.OTHERS]?.length || false;
        }
        case categories.DIRECT_REPORTING: {
          return loggedUser?.isReportingManager === true || false;
        }
        case categories.BU: {
          return (
            loggedUser?.isBUHead === true || loggedUser?.isHRBP === true || false
          );
        }
        case categories.PROJECT: {
          return loggedUser?.isProjectManager === true || false;
        }
        default:
          return true;
      }
    })
    .map((key) => (
      <Tab
        key={categories[key]}
        className="live-rank-tab-container"
        label={`${categories[key]}`}
        value={categories[key]}
      />
    ));

  const col = [
    {
      header: 'Employee Name',
      accessor: 'fullName',
    },
    {
      header: 'Employee Email',
      accessor: 'email',
    },
    {
      header: 'Business Unit',
      accessor: 'department',
    },
  ];

  const report = [
    {
      icon: <VisibilityIcon />,
      label: 'View',
      onClick: () => { },
      color: 'primary-icon',
    },
  ];
  const handleChange = async (e: any) => {
    try {
      const selectedName = e.target.value;
      if (!categoryList.some((item) => item.artifact === selectedName)) {
        console.error('Invalid Category Selected');
        return;
      }
  
      const params = {
        hasArtifact: selectedName === 'Has Artifact' ? 1 : 0,
        hasNoArtifact: selectedName === 'No Artifact' ? 1 : 0,
        All: selectedName === 'All' ? 1 : 0,
        approved: selectStatus?.join(',').toLowerCase(),
        year: currentYear,
      };
  
      if (!params.hasArtifact) delete params.hasArtifact;
      if (!params.hasNoArtifact) delete params.hasNoArtifact;
      if (!selectStatus.length) delete params.approved;
  
      setSelectedCategory(selectedName);
  
      const response = await CommHandler.request({
        url: `${config.apiBaseUrl}/artifact/users`,
        method: 'GET',
        params,
        headers: getAuthHeader,
      });
  
      if (response?.data?.length) {
        setData(response?.data[0] || {});
      }
    } catch (error) {
      setLoadingTable(false);
    } finally {
      setLoadingTable(false);
    }
  };

  const downloadCsv = () => {
    if (!tabData?.length) return;
    try{
    const result = tabData.flatMap((user) => {
      if (!user || !user?.artifacts?.length) {
        return {
          'Employee Name': user?.fullName || '',
          'Employee Email': user?.email || '',
          'Employee Code': user?.employeeID || '',
          'Business Unit': user?.department || '',
          'Category': '',
          'Sub Category': '',
          'Artifact Name': '',
          'Approver List': '',
          'Status': '',
          'Approved By': '',
          'Description': '',
          'Points': '',
          'Attachments': '',
        };
      }

      return user.artifacts.map((artifact) => ({
        'Employee Name': user?.fullName || '',
        'Employee Email': user?.email || '',
        'Employee Code': user?.employeeID || '',
        'Business Unit': user?.department || '',
        'Artifact Name': artifact?.artifactName || '',
        'Category': artifact?.category || '',
        'Sub Category': artifact?.subCategory || '',
        'Approver List': formatApproverList(artifact?.approverList) || '',
        'Status': artifact?.status || '',
        'Approved By': artifact?.approvedBy || '',
        'Description': artifact?.description || '',
        'Points': artifact?.points || '',
        'Attachments': artifact?.attachments?.join(', ') || '',
      }));
    });
  
    const csvData = convertArrayOfObjectsToCsv(result, [
      { label: 'Employee Name', key: 'Employee Name' },
      { label: 'Employee Email', key: 'Employee Email' },
      { label: 'Employee Code', key: 'Employee Code' },
      { label: 'Business Unit', key: 'Business Unit' },
      { label: 'Artifact Name', key: 'Artifact Name' },
      { label: 'Category', key: 'Category' },
      { label: 'Sub Category', key: 'Sub Category' },
      { label: 'Approver List', key: 'Approver List' },
      { label: 'Status', key: 'Status' },
      { label: 'Approved By', key: 'Approved By' },
      { label: 'Description', key: 'Description' },
      { label: 'Points', key: 'Points' },
      { label: 'Attachments', key: 'Attachments' },
    ]);
  
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Artifact_Report.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error generating CSV:', error);
    alert('Failed to download CSV. Please try again.');
    }
  };  
  
  const formatApproverList = (approverList) => {
  if (!approverList) return '';

  if (Array.isArray(approverList)) {
    return approverList
      .filter((approver) => approver && approver.name)
      .map((approver) => approver.name)
      .join(', ');
  }

  if (typeof approverList === 'object') {
    const primary = approverList.primaryApprover
      ?.filter((a) => a && a.name) 
      .map((a) => a.name) || [];

    const secondary = approverList.secondaryApprover
      ?.filter((a) => a && a.name) 
      .map((a) => a.name) || [];

    return [...primary, ...secondary].join(', ');
  }

  return '';
};

  return (
    <ArtifactWrapper>
      <div className="artifact-btn-space">
        <div>
          <Typography variant="h1" gutterBottom>
            Team Artifacts
          </Typography>
        </div>
      </div>
      <div className="employee-nav">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <TabContext value={tabValue!}>
            <TabList onChange={handleTabChange}>
              {getTabComponent()}
            </TabList>
          </TabContext>
          <div>
            <FormControl sx={{ m: 1, width: 180 }} variant="outlined">
              <InputLabel id="category-select-label" shrink>
                Select Category
              </InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={selectedCategory || ''}
                onChange={(e) => handleChange(e)}
                input={<OutlinedInput label="Select Category*" />}
                displayEmpty
                renderValue={(selected) =>
                  selected ? (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: 0.5 }}>
                      <Checkbox checked size="small" sx={{ p: 0, mr: 0.5 }} />
                      <Tooltip title={selected.length > 15 ? selected : ''} placement="top">
                        <Chip label={selected.length > 15 ? `${selected.slice(0, 15)}...` : selected} />
                      </Tooltip>
                    </Box>
                  ) : (
                    <em style={{ fontStyle: "normal" }}>Select Category*</em>
                  )
                }
                MenuProps={MenuProps}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    top: 0,
                  },
                  '& .MuiInputLabel-outlined': {
                    top: '-6px',
                  },
                }}
              >
                {categoryList.map((item) => (
                  <MenuItem key={item.id} value={item.artifact}>
                    <Checkbox checked={selectedCategory === item.artifact} size="small" sx={{ p: 0.5 }} />
                    <ListItemText primary={item.artifact} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {hasDownloadPermission &&
            (
              <Button
                style={{
                  width: '125px',
                  height: '41px',
                  marginRight: '15px',
                  marginLeft: '15px',
                  marginTop: '15px',
                  textTransform: 'none',
                }}
                variant="contained"
                className="addArtifact-btn"
                onClick={downloadCsv}
              >
                <DownloadIcon/>
                Download
              </Button>
            )}
          </div>
        </Box>
      </div>
      <ListTable
        title="Team"
        tabValue={tabValue!}
        columns={col}
        data={tabData?.sort((a, b) => a?.fullName?.localeCompare(b?.fullName)) || []}
        actions={report}
        onActionClick={(action, item) => {
          handleActionClick(action, item);
        }}
        isStatus={false}
        loading={loadingTable}
      />
    </ArtifactWrapper>
  );
}
