/* eslint-disable */
import * as React from 'react';
import {
  Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Card, CardContent, Typography,
} from '@mui/material';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedStatus, setHasArtifact, setTabValue } from '../modules/Profile/selectedStatusSlice';
import { setSelectedSubcategory } from '../modules/Profile/selectedSubcategorySlice';
import { setSelectedTab } from '../modules/Profile/selectedTabSlice';
import { checkPermissions } from '../utils/roles/permissionUtils';

const drawerWidth = 80;

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeItem, setActiveItem] = React.useState('/');
  const [isHoveringArtifacts, setIsHoveringArtifacts] = React.useState(false);
  const user = useSelector((state: any) => state.user.employee);
  const firstCategoryId = useSelector((state: any) => state?.selectedTab?.firstCategoryId);
  const [submenuState, setSubmenuState] = React.useState(null);

  const hasHomePermission = checkPermissions('PMSHOME', ['Read', 'Edit', 'Write', 'Delete']);
  const hasMyPerformancePermission = checkPermissions('PMSMYPER', ['Read', 'Edit', 'Write', 'Delete']);
  const hasTeamPerformancePermission = checkPermissions('PMSTEAMPER', ['Read', 'Edit', 'Write', 'Delete']);
  const hasOverallPermission = checkPermissions('PMSCOMART', ['Read']) || false;
  const hasGrowthPermission = checkPermissions('PMSGROWTH', ['Read', 'Edit', 'Write', 'Delete']);

  const previousPath = React.useRef(null);

  React.useEffect(() => {
    const sanitizePath = (path) => path
      .replace(/\/user-artifact-detail\/[^/]+/, '/user-artifact-detail')
      .replace(/\/artifacts\/viewArtifact\/[^/]+/, '/artifacts/viewArtifact')
      .replace(/\/artifacts\/editArtifact\/[^/]+\/[^/]+/, '/artifacts/editArtifact');

    const currentPath = sanitizePath(location?.pathname);
    const previousSanitizedPath = previousPath.current ? sanitizePath(previousPath.current) : null;
    const isArtifactDetail = currentPath === '/artifact-detail';
    const isUserArtifactDetail = currentPath === '/user-artifact-detail';
    const isViewArtifact = currentPath === '/artifacts/viewArtifact';
    const isEditArtifact = currentPath === '/artifacts/editArtifact';
    const cameFromViewOrEditArtifact = ['/artifacts/viewArtifact', '/artifacts/editArtifact'].includes(previousSanitizedPath);
    if (previousSanitizedPath !== null) {
      if (!(isArtifactDetail || isUserArtifactDetail || isViewArtifact || isEditArtifact)) {
        dispatch(setSelectedSubcategory([]));
        dispatch(setSelectedTab(firstCategoryId));
      }
      if ((!cameFromViewOrEditArtifact && (isArtifactDetail || isUserArtifactDetail)) || (previousSanitizedPath === '/user-artifact-detail' && isArtifactDetail)) {
        dispatch(setSelectedSubcategory([]));
      }
    }

    previousPath.current = location.pathname;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname, dispatch]);

  const handleItemClick = (route: any, subMenu: any, text: any) => {
    const isTeamArtifact = location.pathname === '/team-artifacts';

    if (!isTeamArtifact) {
      dispatch(setSelectedTab(firstCategoryId));
      dispatch(setSelectedStatus([]));
      dispatch(setHasArtifact(false));
      dispatch(setTabValue('All'));
    }
    if (!subMenu.length) {
      navigate(route);
    }

    if (text === 'Artifacts') {
      setIsHoveringArtifacts(true);
    }
    if (text === 'Home') {
      dispatch(setSelectedSubcategory([]));
      dispatch(setSelectedStatus([]));
      dispatch(setHasArtifact(false));
      dispatch(setTabValue('All'));
    }
    setActiveItem(route);
  };

  React.useEffect(() => {
    setActiveItem(location.pathname);
  }, [location.pathname]);

  const handleMouseEnter = (text) => {
    if (text === 'Home') {
      setIsHoveringArtifacts(false);
    } else {
      setIsHoveringArtifacts(true);
    }
  };

  const subMenuHandler = (submenu: any) => {
    if (submenuState !== submenu.text) {
      dispatch(setSelectedSubcategory([]));
      dispatch(setSelectedStatus([]));
      dispatch(setHasArtifact(false));
      dispatch(setTabValue('All'));
      setSubmenuState(submenu.text);
    }
  };

  const handleMouseLeave = () => {
    setIsHoveringArtifacts(false);
  };

  // const handleTabChange = (event: any, newValue: any) => {
  //   setSelectedTab(newValue);
  //   const route = newValue === 0 ? '/artifacts' : '/';
  //   navigate(route);
  // };

  // const hasTeamPerformanceAccess = user?.isBUHead || user?.isReportingManager || user?.isProjectManager || user?.isHRBP || user?.isSuperAdmin;
  const subMenuList = [
    ...(hasMyPerformancePermission ? [
    {
      text: 'My Performance',
      route: '/artifact-detail',
      hasEditAccess: true,
    },
    ] : []),
    ...(hasTeamPerformancePermission ? [
    {
      text: 'Team Performance',
      route: '/team-artifacts',
    },
    ] : []),
    ...(hasOverallPermission ? [
      {
        text: 'Overall Performance',
        route: '/company-artifacts',
      },
      ] : []),
  ];
  // .filter((item) => (!hasTeamPerformanceAccess && item.text !== 'Team Performance' ? true : hasTeamPerformanceAccess));

  const menuList = [
    ...(hasHomePermission ? [
    {
      text: 'Home',
      imageURL: '/Home.svg',
      route: '/',
    },
    ] : []),
    ...((hasMyPerformancePermission || hasTeamPerformancePermission) ? [
    {
      text: 'Artifacts',
      imageURL: '/art.png',
      route: '/artifacts',
      subMenu: subMenuList,
    },
    ] : []),
    ...(hasGrowthPermission ? [
    {
      text: 'Growth',
      imageURL: '/art.png',
      route: '/growth',
    },
    ] : []),
  ];

  if (user?.isSuperAdmin) {
    subMenuList.push({
      text: 'Overall Performance',
      route: '/company-artifacts',
    });
    delete menuList[1].subMenu[0];
  }

  const list = () => (
    <List sx={{ paddingTop: '15px' }}>
      {menuList.map(({
        text, imageURL, route, subMenu = [],
      }) => {
        const isArtifactsSelected = (route !== '/' && (activeItem.startsWith(route) || subMenu.some((subItem) => activeItem === subItem.route))) || route === activeItem;
        return (
          <ListItem
            sx={{
              alignContent: 'space-between',
              alignItems: 'center',
              color: '#fff',
              display: 'grid',
              justifyContent: 'center',
              borderLeft: isArtifactsSelected ? '3px solid #DE4E35' : 'none',
            }}
            key={text}
            onClick={!isArtifactsSelected ? () => handleItemClick(route, subMenu, text) : undefined}
            onMouseEnter={isArtifactsSelected && text === 'Artifacts' ? () => handleMouseEnter(text) : undefined}
            onMouseLeave={isArtifactsSelected && text === 'Artifacts' ? handleMouseLeave : undefined}
          >
            <ListItemIcon sx={{ display: 'block', textAlign: 'center', cursor: 'pointer' }}>
              <img src={imageURL} alt="svg" width="16px" />
            </ListItemIcon>
            <ListItemText sx={{ textAlign: 'center', cursor: 'pointer' }} primary={text} />
            {isHoveringArtifacts && text === 'Artifacts' && (
              <Card
                style={{
                  width: '180px',
                  position: 'absolute',
                  left: '76px',
                  top: '8px',
                }}
              >
                <CardContent>
                  {/* <Tabs
                  value={selectedTab}
                  onChange={handleTabChange}
                  centered
                /> */}
                  {subMenu.map((menu) => (
                    <Typography key={menu.text} className="sidebar-menu-link">
                      <NavLink to={menu.route} state={{ hasEditAccess: menu?.hasEditAccess ?? false }} className="menu-link" onClick={() => subMenuHandler(menu)}>
                        {menu.text}
                      </NavLink>
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            )}
          </ListItem>
        );
      })}
    </List>
  );

  return (
    <Drawer
      className="side-bar-container"
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          background: '#2559C3',
          paddingTop: '12px',
          overflowY: 'unset',
        },
      }}
    >
      <Toolbar />
      {list()}
    </Drawer>
  );
}

export default Sidebar;
