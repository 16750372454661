/* eslint-disable */
import {
  Box,
  Grid,
  Tooltip,
  Typography,
  Paper,
  Avatar,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import OnDeviceTrainingIcon from '@mui/icons-material/OnDeviceTraining';
import { useNavigate } from 'react-router-dom';
import { getAuthHeader } from '../../../utils/utils';
import { CommHandler } from '../../../utils';
import { config } from '../../../config';
import { KeyResponsibilityWrapper } from './KeyResponsibility.styled';
import { checkPermissions } from '../../../utils/roles/permissionUtils';
import { useSelector } from 'react-redux';
import {getCurrentYear } from '../../../utils/utils';
const ShowClonnedUser = ({ apidata }) => {
  const selectedYear = (useSelector((state: any) => state.appraisalCycle.selectedYear) || getCurrentYear())?.toString();
  const currentFinancialYear=`${selectedYear}-${parseInt(selectedYear)+1}`;
  const managerEmail = apidata?.email;
  const id = apidata?.id;
  const [showClonedReportee, setClonedReportee] = useState([]);
  const navigate = useNavigate();
  const hasClonePermission = checkPermissions('PMSMYPROFILE:SUBUSERSCLONED', ['Read'], true);
  const hasCloneEditPermission = checkPermissions('PMSMYPROFILE:SUBUSERSCLONED', ['Write'], true);

  useEffect(() => {
    if(hasClonePermission){
      const fetch = async () => {
        try {
          const { data }: any = await CommHandler.request({
            url: `${config.apiBaseUrl}/cloning?email=${encodeURIComponent(managerEmail)}`,
            method: 'GET',
            headers: getAuthHeader,
            params: {
              financialYear: currentFinancialYear,
            },
          });
          if (data?.users?.length > 0) {
            const filterReportee = data.users.filter((item:any) => data.clonned_users.includes(item.user_id));
            setClonedReportee(filterReportee);
          }
          return data;
        } catch (err) {
          return false;
        }
      };
      fetch();
    }
  }, []);
  const actionButtons = [
    {
      icon: <EditIcon />,
      msg: 'Edit User',
      label: 'Edit',
      color: 'primary-icon',
    },
  ];

  const handleAction = () => {
    const data = {
      email: managerEmail,
      id,
    };
    navigate('/viewClone', { state: data });
  };

  return (
    <>
    {hasClonePermission && (
    <KeyResponsibilityWrapper>
      <Paper className="key-responsibility-paper-wrap">
        <Grid item xs={12} className="key-title-wrap">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Typography className="key-title-text" variant="h5" gutterBottom>
                Users Cloned
              </Typography>
            </div>
            {hasCloneEditPermission && (
            <div>
              {actionButtons?.map((btn) => (
                <Tooltip title={btn.msg} arrow key={btn.label}>
                  <Button
                    sx={{ minWidth: '8px', color: '#2559C3' }}
                    onClick={() => handleAction()}
                  >
                    {btn.icon}
                  </Button>
                </Tooltip>
              ))}
            </div>
            )}
          </div>
        </Grid>
        <Grid container spacing={2} className="key-container-wrap">

          {' '}
          {showClonedReportee && showClonedReportee.length ? (
            showClonedReportee.map((key) => (
              <Grid item xs={12} key={key} className="key-response-wrapper">
                <Box className="key-icon-text-wrapper" gap={1}>
                  <Avatar className="key-icon-wrap">
                    <OnDeviceTrainingIcon className="key-icon" />
                  </Avatar>
                  <Typography
                    variant="body2"
                    className="key-data-text"
                    gutterBottom
                  >
                    {key.fullName}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="key-data-text"
                    gutterBottom
                  >
                    { }
                  </Typography>
                  <Typography
                    variant="body2"
                    className="key-data-text"
                    gutterBottom
                  >
                    {key.designation}
                  </Typography>
                </Box>
              </Grid>
            ))
          ) : (
            <Typography
              gutterBottom
              align="center"
              className="key-no-data-text"
            >
              No data to display
            </Typography>
          )}

        </Grid>
      </Paper>
    </KeyResponsibilityWrapper>
  )}
  </>
  );
};

export default ShowClonnedUser;
