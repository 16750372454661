/* eslint-disable */
import { useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { Document, Page, pdfjs } from 'react-pdf';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import CloseIcon from '@mui/icons-material/Close';
import { ViewImage } from './imagePreview.styled';

pdfjs.GlobalWorkerOptions.workerSrc = `/pdfjs/pdf.worker.min.js`;

const ImagePreview = (data: any) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { type, url, setshowBuArtifact } = data;
  const images = [];
  if (type !== 'pdf') {
    images.push(url);
  }
  const [isViewerOpen, setIsViewerOpen] = useState(true);
  const closeImageViewer = () => {
    setIsViewerOpen(false);
    setshowBuArtifact(false);
  };

  return (
    type !== 'pdf' ? (
      <ViewImage className="image-preview">
        <CloseIcon onClick={closeImageViewer} />
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={0}
            disableScroll={false}
            closeOnClickOutside
            onClose={closeImageViewer}
            backgroundStyle={{ backgroundColor: 'rgb(231 229 229 / 0.5%)' }}
            closeComponent={null}
          />
        )}
      </ViewImage>
    ) : (
      <div style={{ padding: '30px', position: 'relative' }}>
        <CloseIcon onClick={closeImageViewer} style={{ position: 'absolute', right: '6px', top: '5px', cursor: 'pointer' }} />
        <Document
          file={url}
          onLoadSuccess={({ numPages }) => setNumPages(numPages)}
          onLoadError={(error) => console.error('Error loading PDF:', error)}
        >
          <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
        </Document>
        {numPages && (
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <button disabled={pageNumber <= 1} onClick={() => setPageNumber(pageNumber - 1)}>Previous</button>
            <span> Page {pageNumber} of {numPages} </span>
            <button disabled={pageNumber >= numPages} onClick={() => setPageNumber(pageNumber + 1)}>Next</button>
          </div>
        )}
      </div>
    )
  );
};
export default ImagePreview;
