/* eslint-disable */
import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PrivateAppLayout from '../layouts/PrivateAppLayout';
import AuthRoute from './AuthRoute';
import { BUArtifact } from '../modules/BUArtifact';
import CompanyArtifact from '../modules/BUArtifact/CompanyArtifacts';
import { Profile } from '../modules/Profile';
//import PreviewPage from '../modules/Artifact/components/PreviewPage';
import { NotFound } from '../modules/NotFound';
import AddArtifacts from '../modules/Artifact/components/AddArtifacts';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { getCurrentYear } from '../utils/utils';
import { storeAppraisalCycleSelected } from '../modules/Profile/components/AppraisalCycleSlice';
import ViewAllArtifact from '../modules/Artifact/components/ViewAllArtifact';
import Growth from '../modules/Growth/Growth';
import ViewArtifactDetails from '../modules/Artifact/components/ViewArtifact';
import { AddProjectLevelArtifact } from '../modules/Artifact/components';
import ArtifactList from '../modules/Artifact/components/ArtifactList';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import ViewClone from '../modules/ViewClone/ViewClone';
import TokenCheck from "../utils/TokenCheck"
const Dashboard = lazy(() => import('../modules/Dashboard/Dashboard'));
const LoginPage = lazy(() => import('../modules/Login/Login'));
const LiveRankingExpend = lazy(() => import('../modules/Dashboard/components/LiveRanking/LiveRankingExpend'));

const AppRouter = () => {
  const isLoading = useSelector((state: RootState) => state.roles.isLoading);
  const path = window.location.pathname;
  if (isLoading && path !== '/login' && path !== '/*') {
    return <div>Loading...</div>;
  }

  const dispatch = useDispatch();
  dispatch(storeAppraisalCycleSelected(getCurrentYear()));
  const authAppRouter = createBrowserRouter([
    {
      path: '/',
      element: <AuthRoute />,
      children: [
        {
          path: '/',
          element: (
            <>
              <TokenCheck />
              <PrivateAppLayout />
            </>
          ),
          children: [
            {
              path: '/',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <Dashboard />
                </Suspense>
              ),
            },
            {
              path: '/team-artifacts',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ProtectedRoute code="PMSTEAMPER" />
                  <BUArtifact />
                </Suspense>
              ),
            },
            {
              path: '/company-artifacts',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ProtectedRoute code="ADGROWTHREP" />
                  <CompanyArtifact />
                </Suspense>
              ),
            },
            {
              path: '/artifacts/add-artifacts',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ProtectedRoute code="PMSMYPER:SUBMYPERDET" actionTypes={['Write']} />
                  <AddArtifacts />
                </Suspense>
              ),
            },
            {
              path: '/artifacts/add-artifacts/form/:text/:id/:categoryCode',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ProtectedRoute code="PMSMYPER:SUBMYPERDET" actionTypes={['Write']} />
                  <AddProjectLevelArtifact />
                </Suspense>
              ),
            },
            {
              path: '/artifact-detail',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ProtectedRoute code="PMSMYPER" />
                  <ArtifactList />
                </Suspense>
              ),
            },
            {
              path: '/user-artifact-detail/:userId',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ProtectedRoute code="PMSMYPER" />
                  <ArtifactList />
                </Suspense>
              ),
            },
            // {
            //   path: '/artifacts/add-artifacts/preview',
            //   element: (
            //     <Suspense fallback={<div>Loading...</div>}>
            //       <ProtectedRoute code="PMSMYPER:SUBMYPERDET" actionTypes={['Write']} />
            //       <PreviewPage />
            //     </Suspense>
            //   ),
            // },
            {
              path: '/liveRankingExpand',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ProtectedRoute code="PMSLIVERANK" />
                  <LiveRankingExpend rankings={[]} />
                </Suspense>
              ),
            },
            {
              path: '/Profile',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ProtectedRoute code="PMSMYPROFILE" />
                  <Profile />
                </Suspense>
              ),
            },
            {
              path: '/artifacts/editArtifact/:id/:categoryId/:categoryNames',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ProtectedRoute code="PMSMYPER:SUBMYPERDET" actionTypes={['Edit']} />
                  <AddProjectLevelArtifact />
                </Suspense>
              ),
            },
            {
              path: '/artifacts/viewArtifact/:artifactFormId',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ProtectedRoute code="PMSMYPER:SUBMYPERDET" actionTypes={['Read']} />
                  <ViewArtifactDetails />
                </Suspense>
              ),
            },
            {
              path: '/artifacts/viewAll-artifact/:userId?',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ProtectedRoute code="PMSMYPER:SUBMYPERDET" actionTypes={['Read']} />
                  <ViewAllArtifact />
                </Suspense>
              ),
            },
            {
              path: '/growth',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ProtectedRoute code="PMSGROWTH" />
                  <Growth />
                </Suspense>
              ),
            },
            {
              path: '/viewClone',
              element: (
                <Suspense fallback={<div>Loading...</div>}>
                  <ProtectedRoute code="PMSCLONE" />
                  <ViewClone/>
                </Suspense>
              ),
            },
          ],
        },
      ],
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/login',
      element: <LoginPage />,
    },
    {
      path: '/*',
      element: <NotFound />,
    },
  ]);

  return (<RouterProvider router={authAppRouter} />);
};

export default AppRouter;
